@font-face {
  font-family: 'Girloub';
  src: local('Girloub'), url(./fonts/Gilroy-Black.woff) format('woff');
  }
@font-face {
  font-family: 'Alata';
  src: local('Alata'), url(./fonts/Alata-Regular.ttf) format('ttf');
  }
html, body, #root{
    background-color: #1e2334
}
/*Girloub*/
/*Girlous*/
